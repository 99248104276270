.App {
  height: 100%;
  min-height: 100%;
  color: white;
}

body {
  background-color: #222222;
  height: 100%;
}

html {
  height: 100%;
  min-height: 100%;
}

.user-select-none {
  user-select: none;
}

#root {
  height: 100%;
  min-height: 100%;
}

ul {
  list-style: none;
}

.fill {
  margin: 0;
}

.success {
  background-color: #00363a;
}

.failure {
  background-color: #7f0000;
}

.clickable:hover {
  filter: brightness(75%);
  cursor: pointer;
}

.fullimage {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.headrow {
  height: calc(2.25rem + 2px);
}

.svg {
  max-height: 100%;
}

.flip {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}